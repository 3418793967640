import React from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { FaGlobe, FaPhoneAlt } from "react-icons/fa"
import { IconContext } from "react-icons"
import ContentWrapper from "../components/contentWrapper"

function LinksPage() {
  return (
    <ContentWrapper title='Links'>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <h1 id="links-und-kollegen">Links und Kollegen</h1>
            <p>In meiner langjährigen Arbeit als Ärztin hatte ich das Glück, viele kompetente Kollegen kennenzulernen,
              die ihre Patienten in ihren spezifischen Fachbereichen exzellent betreuen.</p>
            <p>Dies ermöglichte mir schon oft eine gute Zusammenarbeit, die meinen Patienten die Sicherheit einer
              umfassenden Diagnostik und optimaler Behandlung gab sowie kurze Wartezeiten gewährleistete.</p>
            <p>Auf Wunsch helfe ich Ihnen gerne, geeignete Kollegen und den für Sie idealen Behandlungsweg zu
              finden.</p>
            <h4>
              Dr. Angela Lee-Schultze <small className='text-muted'>Praxis für Psychotherapie und Allgemeinmedizin</small>
            </h4>
            Maximilianstraße 3/2<br />
            6020 Innsbruck
            <p>
              <IconContext.Provider value={{ color: "#333" }}>
                <FaPhoneAlt />
                &nbsp;
                <a href="tel:+436605021651"> +43 660 5021651</a><br />
                <FaGlobe />
                &nbsp;
                <a href="http://www.lee-schultze.at" target="_blank" rel="noreferrer" >www.lee-schultze.at</a>
              </IconContext.Provider>
            </p>
            <p>Weitere Einträge folgen</p>
        </Col>
      </Row>
    </ContentWrapper>
  );
}

export default LinksPage;
